import EmblaCarousel from 'embla-carousel';
const TWEEN_FACTOR_BASE = 0.8;
const SETTLE_PIXEL_THRESHOLD = 50;
const TRANSLATE_FACTOR = 200;

const numberWithinRange = (number, min, max) => Math.min(Math.max(number, min), max);

class HostsSlider {
  constructor() {
    this.emblaNode = document.getElementById('emblaRef');
    this.slidesData = []
    this.tweenNodes = [];
    this.setupEmbla();
    this.createSlides();
    this.setupEventListeners();
  }

  setupEmbla() {
    this.emblaApi = EmblaCarousel(this.emblaNode, {
      loop: true,
      align: "center",
      dragFree: true
    });

    // Add references to navigation buttons
    this.prevButton = this.emblaNode.querySelector('.sliderScale__arrow--prev');
    this.nextButton = this.emblaNode.querySelector('.sliderScale__arrow--next');

    const slides = this.emblaNode.querySelectorAll('span');

    slides.forEach((itm) => {
      this.slidesData.push({
        img: itm.dataset.img,
        href: itm.dataset.href,
        name: itm.dataset.name
      })
      itm.remove();
    })
  }

  createSlides() {
    const container = this.emblaNode.querySelector('.sliderScale__container');

    this.slidesData.forEach((itm, idx) => {
      const slideDiv = document.createElement('div');
      slideDiv.className = `sliderScale__slide slide-${idx}`;
      slideDiv.style.zIndex = 20 - idx;
      slideDiv.style.cursor = 'pointer';

      const img = document.createElement('img');
      img.className = 'host-img-slider-item';
      img.src = itm.img
      img.width = 370;
      img.height = 660;
      img.alt = 'Whatever';

      const link = document.createElement('a');
      link.className = 'host-img-slider-text';
      link.innerHTML = itm.name;
      link.href = itm.href;

      slideDiv.appendChild(img);
      slideDiv.appendChild(link);
      slideDiv.addEventListener('click', () => this.scrollToSlide(idx));

      // Add hover effects for main slide
      slideDiv.addEventListener('mouseenter', () => this.highlightSlide(idx));
      slideDiv.addEventListener('mouseleave', () => this.unhighlightSlide(idx));

      container.appendChild(slideDiv);
    });
  }

  scrollToSlide(index) {
    if (!this.emblaApi) return;
    this.emblaApi.scrollTo(index);
  }

  fireCustomSettle() {
    const { dragHandler, location, target } = this.emblaApi.internalEngine();
    if (dragHandler.pointerDown()) return;

    const displacement = target.get() - location.get();
    if (Math.abs(displacement) < SETTLE_PIXEL_THRESHOLD) {
      this.emblaApi.scrollTo(this.emblaApi.selectedScrollSnap());
    }
  }

  setTweenNodes() {
    this.tweenNodes = this.emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector(".host-img-slider-item");
    });
  }

  tweenScale(eventName) {
    const engine = this.emblaApi.internalEngine();
    const scrollProgress = this.emblaApi.scrollProgress();
    const slidesInView = this.emblaApi.slidesInView();
    const isScrollEvent = eventName === "scroll";

    this.emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap?.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR_BASE);
        const scale = numberWithinRange(tweenValue, 0, 1).toString();

        // Calculate translateX - move toward center based on distance from center
        // The sign of diffToTarget tells us if the slide is to the left or right of center
        // We want slides to move in the opposite direction of their position (toward center)
        const translateDirection = -Math.sign(diffToTarget);
        // The amount of translation decreases as the slide gets closer to center
        const translateAmount = (1 - tweenValue) * TRANSLATE_FACTOR * translateDirection;

        const tweenNode = this.tweenNodes[slideIndex];

        if (!tweenNode) return;
        // Apply both scale and translateX transformations
        if (window.innerWidth <= 768) {
          tweenNode.style.transform = `scale(${scale}) translateX(${translateAmount}px)`;
        } else {
          tweenNode.style.transform = `scale(${scale})`;
        }
        const parent = tweenNode.parentElement;
        if (parent) {
          parent.style.zIndex = `${Math.round(tweenValue * 100)}`;
        }
      });
    });
  }

  setupEventListeners() {
    this.setTweenNodes();
    this.tweenScale();

    const boundTweenScale = this.tweenScale.bind(this);
    const boundSetTweenNodes = this.setTweenNodes.bind(this);
    const boundFireCustomSettle = this.fireCustomSettle.bind(this);

    // Main slider events
    this.emblaApi.on('reInit', boundSetTweenNodes);
    this.emblaApi.on('reInit', boundTweenScale);
    this.emblaApi.on('scroll', boundTweenScale);
    this.emblaApi.on('scroll', boundFireCustomSettle);
    this.emblaApi.on('slideFocus', boundTweenScale);

    // Add click handlers for navigation buttons
    if (this.prevButton) {
      this.prevButton.addEventListener('click', () => {
        this.emblaApi.scrollPrev();
      });
    }

    if (this.nextButton) {
      this.nextButton.addEventListener('click', () => {
        this.emblaApi.scrollNext();
      });
    }
  }

  // Add new methods for handling hover effects
  highlightSlide(index) {
    const slideNodes = this.emblaNode.querySelectorAll('.sliderScale__slide');
    if (slideNodes[index]) {
      slideNodes[index].classList.add('is-active');
    }
  }

  unhighlightSlide(index) {
    const slideNodes = this.emblaNode.querySelectorAll('.sliderScale__slide');
    if (slideNodes[index]) {
      slideNodes[index].classList.remove('is-active');
    }
  }
}

// Initialize the slider when the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  new HostsSlider();
});